<template>
  <div class="case">
    <div v-if="vertical">
      <div class="title-vertical">相关案例</div>
      <div class="container-vertical">
        <div v-for="(item, index) of data" :key="index" class="item">
          <div class="title">{{ item.title }}</div>
          <div class="des">
            案例内容: {{ item.description }}
          </div>
          <div class="details" @click="showDialog(item)">查看详情</div>
        </div>
      </div>
    </div>
    <div v-else>
      <Title :title="'相关案例'" />
      <div class="container">
        <div v-for="(item, index) of data" :key="index" class="item">
          <div class="title">{{ item.title }}</div>
          <div class="tag">【案例内容】</div>
          <div class="des">
            {{ item.description }}
          </div>
          <div class="details" @click="showDialog(item)">查看详情</div>
        </div>
      </div>
    </div>
    <el-dialog
      class="dialogCase"
      title="案例详情"
      :visible.sync="dialogCaseVisible"
    >
      <div class="title">{{ dialogCase.title }}</div>
      <div class="tag">【案例内容】</div>
      <div class="des">
        {{ dialogCase.description }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Title from '@/components/Title'
export default {
  name: 'Case',
  components: {
    Title,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dialogCaseVisible: false,
      dialogCase: {},
    }
  },
  methods: {
    showDialog(item) {
      this.dialogCase = item
      this.dialogCaseVisible = true
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.case {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 22px 0px rgba(229, 232, 234, 0.5);

  .container {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 40px;
    .item {
      position: relative;
      box-sizing: border-box;
      width: 280px;
      height: 310px;
      padding: 40px 20px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(2, 24, 36, 0.04);
      border: 1px solid #e5e8ed;
      .title {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        .uni-ellipsis2;
      }
      .tag {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }
      .des {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        .uni-ellipsis3;
      }
      .details {
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        width: 220px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid @uni-text-color-select;
        line-height: 36px;
        text-align: center;
        color: @uni-text-color-select;
        cursor: pointer;
        &:hover {
          border: none;
          background: linear-gradient(180deg, #05d0fe 0%, #2c6fe8 100%);
          color: #fff;
        }
      }
    }
  }
  .title-vertical {
    padding: 20px;
    line-height: 28px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
  }
  .container-vertical {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid #eee;
      .title {
        width: 220px;
        margin: 10px 0;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        .uni-ellipsis2;
      }
      .des {
        width: 220px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        .uni-ellipsis4;
      }

      .details {
        margin: 20px 0;
        box-sizing: border-box;
        width: 226px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid @uni-text-color-select;
        line-height: 36px;
        text-align: center;
        color: @uni-text-color-select;
        cursor: pointer;
        &:hover {
          border: none;
          background: linear-gradient(180deg, #05d0fe 0%, #2c6fe8 100%);
          color: #fff;
        }
      }
    }
  }

  .dialogCase {
    & /deep/ .el-dialog__header {
      .uni-dialog__header;
    }
    & /deep/ .el-dialog {
      width: 760px;
    }
    & /deep/ .el-dialog__body {
      .uni-dialog__body;
    }
    .title {
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
    .tag {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
    .des {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
  }
}
</style>
