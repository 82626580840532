<template>
  <div v-loading="loading" class="lawyerDetails">
    <Breadcrumb :data="breData" />
    <div class="top">
      <Card class="top-left" :body-style="{ marginBottom: '20px' }">
        <template v-slot:header> 法律服务详情 </template>
        <template v-slot:actions>
          <div>
            <Submit @click="clickTitleButton">
              <el-button
                style="
                height: 32px;
                padding: 8px 22px;
                border-color: rgba(0, 164, 255, 1);
                margin-right: 10px;
              "
              >
                <span
                  style="color: rgba(0, 164, 255, 1); font-size: 14px"
                >{{ $t('d8a7e67') }}</span>
              </el-button>
            </Submit>
            <el-button
              style="
              height: 32px;
              padding: 8px 22px;
              border-color: rgba(0, 164, 255, 1);
            "
              @click="consultVisible = true"
            >
              <span style="color: rgba(0, 164, 255, 1); font-size: 14px">
                {{ $t('249393c') }}
              </span>
            </el-button>
          </div>
        </template>
        <ServiceDetailsCard
          :data="deskDetailsData"
          :label-data="labelData"
          :close-color="true"
        />
      </Card>
      <div class="top-right">
        <ServiceLawFirm
          :vertical="true"
          :data="deskDetailsData.createInfo"
        />
      </div>
    </div>
    <div class="content">
      <ServiceOther
        class="content-left"
        :data="deskDetailsData.otherService"
      />
      <div class="content-right">
        <Case
          v-if="deskDetailsData.cases.length"
          :vertical="true"
          :data="deskDetailsData.cases"
        />
      </div>
    </div>
    <ServiceDeskDialog ref="serviceDeskDialog" />
    <ConsultModal
      v-if="consultVisible"
      :id="deskDetailsData.id"
      :visible="consultVisible"
      type="SERVICE"
      @close="consultVisible = false"
    />
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import Case from '@/components/Service/ServiceCase'
import ServiceOther from '@/components/Service/ServiceOther'
import ServiceLawFirm from '@/components/Service/ServiceLawFirm'
import ServiceDetailsCard from '@/components/Service/ServiceDetailsCard'
import ServiceDeskDialog from '@/components/Service/ServiceDeskDialog'
import Card from '@/components/Card'
import ConsultModal from '@/components/ConsultModal'
import Submit from '@/components/PermissionContainer/Submit'

import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'DeskDetails',
  components: {
    Breadcrumb,
    Case,
    ServiceOther,
    ServiceLawFirm,
    ServiceDetailsCard,
    ServiceDeskDialog,
    Card,
    ConsultModal,
    Submit,
  },
  data() {
    return {
      loading: true,
      consultVisible: false,
      dialogVisible: false,
      row: 3,
      pageInfo: {
        currentPage: 1,
        size: 9,
        total: 1,
      },
      jumpObj: {
        id: '',
        text: '服务合作',
        path: '',
        event: 'clickTitleButton',
      },
      breData: [
        {
          name: '法律服务',
          path: '/service/desk',
        },
        {
          name: '法律服务详情',
          path: '/service/desk/details',
        },
      ],
      labelData: [
        { label: '服务标题', key: 'title' },
        { label: '服务类型', key: 'serviceType' },
        { label: '涉及区域', key: 'country' },
        { label: '发布时间', key: 'createTime' },
        { label: '服务编码', key: 'code' },
        { label: '服务量', key: 'num' },
        { label: '所属区域', key: 'province' },
        { label: '联系电话', key: 'contactPhone' },
        { label: '联系邮箱', key: 'email' },
        { label: '联系人', key: 'contactPerson' },
        { label: '业务类型', key: 'businessType' },
        { label: '服务详情', key: 'content' },
      ],
    }
  },
  computed: {
    ...mapGetters(['deskDetailsData']),
  },
  watch: {
    'deskDetailsData.id'(id) {
      this.loading = false
      this.jumpObj.id = id
    },
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions(['getLawServiceDetails']),
    getData() {
      this.getLawServiceDetails({
        id: this.$route.query.id,
      })
    },
    clickTitleButton() {
      this.$refs.serviceDeskDialog.dialogVisible = true
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.lawyerDetails {
  width: @uni-width;
  min-height: calc(100vh - 624px);
  margin: 0 auto;
  .top {
    display: flex;
    .top-left {
     width: 920px;
     margin-right: 20px;
    }
    .top-right {
      width: 260px;
      background: #fff;
      margin-bottom: 20px;
      box-shadow: 0px 2px 22px 0px rgba(229, 232, 234, 0.5);
    }
  }
  .content {
    display: flex;
    .content-left {
      width: 920px;
      margin-right: 20px;
    }
    .content-right {
      width: 260px;
      background: #fff;
      margin-bottom: 20px;
      box-shadow: 0px 2px 22px 0px rgba(229, 232, 234, 0.5);
    }
  }
  .serviceDetailsCard {
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
  }
}
</style>
