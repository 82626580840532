<template>
  <div class="case">
    <div class="container">
      <div class="title">{{ data.name | textFilter }}</div>
      <div class="tag">{{ introduce }}介绍：</div>
      <div class="des uni-ellipsis5">{{ data.description | textFilter }}</div>
      <div class="tag">{{ introduce }}地址：</div>
      <div class="des uni-ellipsis2">{{ data.remark | textFilter }}</div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title'
export default {
  name: 'ServiceLawFirm',
  components: {
    Title,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
    }
  },
  computed: {
    introduce() {
      const obj = {
        ENTERPRISE: '企业',
        PERSON: '个人',
        LAWYER: '律师',
        LAW_OFFICE: '机构',
        TRANSLATE: '机构',
        OTHER: '机构',
      }
      return obj[this.data.userType]
    },
  },
  methods: {
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.case {
  margin-bottom: 20px;
  background: #ffffff;

  .container {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px;
    .title {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .tag {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }
    .des {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
    }
  }
}
</style>
