<template>
  <div class="case">
    <Title :title="'其他服务'" />
    <div v-if="data.length" class="container">
      <div v-for="(item, index) of data" :key="index" class="item">
        <div class="title">{{ item.title | textFilter }}</div>
        <div class="time">发布时间：{{ item.createTime | textFilter }}</div>
        <div class="des">
          <div class="tag">发布者</div>
          <div class="content">{{ item.createBy | textFilter }}</div>
        </div>
        <div class="des">
          <div class="tag">所属区域</div>
          <div class="content">{{ province(item) }}</div>
        </div>
        <div class="des">
          <div class="tag">涉及区域</div>
          <div class="content">{{ item.country | textFilter }}</div>
        </div>
        <div class="des">
          <div class="tag">业务类型</div>
          <div class="content uni-ellipsis">{{ item.businessType | textFilter }}</div>
        </div>
        <div class="des">
          <div class="tag">服务描述</div>
          <div class="content content-ellipsis">{{ item.content | textFilter }}</div>
        </div>
        <div class="des">
          <div class="tag">服务量</div>
          <div class="content">{{ item.num | textFilter }}</div>
        </div>

        <div class="details" @click="handleClick(item)">查看详情</div>
      </div>
    </div>
    <div v-else>
      <NoData type="content" />
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title'
import NoData from '@/components/NoData'
export default {
  name: 'ServiceOther',
  components: {
    Title,
    NoData,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    vertical: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dialogCaseVisible: false,
      dialogCase: {},
    }
  },
  methods: {
    province(item) {
      let r = '--'
      const province = item.province
      const city = item.city
      if (province && city) {
        r = `${province}${city}`
      } else if (province && !city) {
        r = `${province}`
      } else if (!province && city) {
        r = `${city}`
      }
      return r
    },
    handleClick(item) {
      const id = item.id
      if (id) {
        window.location = `/service/desk/details?id=${id}`
        // this.$router.push({
        //   path: '/service/desk/details',
        //   query: {
        //     id,
        //   }
        // })
      } else {
        this.$message.error('该服务无id, 无法跳转!')
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.case {
  margin-bottom: 20px;
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 22px 0px rgba(229, 232, 234, 0.5);

  .container {
    display: flex;
    justify-content: space-around;
    padding: 30px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      width: 266px;
      height: 307px;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(2, 24, 36, 0.04);
      border: 1px solid #e5e8ed;
      .title {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        .uni-ellipsis;
        line-height: 16px;
      }
      .time {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }

      .des {
        align-self: flex-start;
        margin-bottom: 10px;
        display: flex;
        .tag {
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          position: relative;
          &:after {
            position: absolute;
            right: 0;
            content: '：';
          }
        }
        .content {
          width: 146px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
        }
        .content-ellipsis {
          .uni-ellipsis2
        }
      }
      .details {
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        width: 226px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid @uni-text-color-select;
        line-height: 36px;
        text-align: center;
        color: @uni-text-color-select;
        cursor: pointer;
        &:hover {
          border: none;
          background: linear-gradient(180deg, #05d0fe 0%, #2c6fe8 100%);
          color: #fff;
        }
      }
    }
  }
  .title-vertical {
    padding: 20px;
    line-height: 28px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
  }
  .container-vertical {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid #eee;
      .title {
        width: 220px;
        margin: 10px 0;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        .uni-ellipsis2;
      }
      .des {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        .uni-ellipsis4;
      }

      .details {
        margin: 20px 0;
        box-sizing: border-box;
        width: 226px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid @uni-text-color-select;
        line-height: 36px;
        text-align: center;
        color: @uni-text-color-select;
        cursor: pointer;
        &:hover {
          border: none;
          background: linear-gradient(180deg, #05d0fe 0%, #2c6fe8 100%);
          color: #fff;
        }
      }
    }
  }

  .dialogCase {
    & /deep/ .el-dialog__header {
      .uni-dialog__header;
    }
    & /deep/ .el-dialog {
      width: 760px;
    }
    & /deep/ .el-dialog__body {
      .uni-dialog__body;
    }
    .title {
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
    .tag {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
    .des {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
  }
}
</style>
