<template>
  <el-dialog
    class="dialogCase"
    :title="dialogTtile"
    :visible.sync="dialogVisible"
    append-to-body
  >
    <el-form
      ref="dialogForm"
      :model="dialogForm"
      :rules="rules"
      class="dialogForm"
      label-width="auto"
    >
      <el-form-item :label="autoPrefix('contactPerson')" prop="contactPerson">
        <el-input
          v-model="dialogForm.contactPerson"
          maxlength="30"
          :placeholder="autoPrefix('contactPerson', 'pla')"
        />
      </el-form-item>
      <el-form-item :label="autoPrefix('contactPhone')" prop="contactPhone">
        <el-input
          v-model="dialogForm.contactPhone"
          maxlength="30"
          :placeholder="autoPrefix('contactPhone', 'pla')"
        />
      </el-form-item>
      <el-form-item :label="autoPrefix('company')" prop="company">
        <el-input
          v-model="dialogForm.company"
          maxlength="30"
          :placeholder="autoPrefix('company', 'pla')"
        />
      </el-form-item>
      <el-form-item :label="autoPrefix('content')" prop="content">
        <el-input
          v-model="dialogForm.content"
          type="textarea"
          maxlength="500"
          :rows="7"
          :placeholder="autoPrefix('content', 'pla')"
          show-word-limit
        />
      </el-form-item>
      <el-form-item class="buttonCntainer">
        <el-button class="release" @click="submitForm()"> 确 定 </el-button>
        <el-button class="cancel" @click="cancelSubmitForm()">
          取 消
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
// const i18nPrefix = {
//   label: 'account.register.dialogForm.lable.',
//   pla: 'account.register.dialogForm.pla.',
// }
import { validateMobilePhone } from '@/utils/helper'
import API from '@/api'
export default {
  name: 'ServiceDeskDialog',
  props: {
  },
  data() {
    return {
      dialogVisible: false,
      dialogForm: {
        contactPerson: '',
        contactPhone: '',
        company: '',
        content: '',
      },
      dialogTtile: '合作内容',
      label: {
        contactPerson: '联系人',
        contactPhone: '联系电话',
        company: '公司',
        content: '合作内容',
      },
      pla: {
        contactPerson: '请输入联系人姓名',
        contactPhone: '请输入联系人电话',
        company: '请输入联系人公司',
        content: '请简述合作内容',
      },
      rules: {
        contactPerson: [{ required: true, message: '请输入联系人姓名' }],
        contactPhone: [
          { required: true, message: '请输入联系人电话' },
          { validator: validateMobilePhone },
        ],
        content: [{ required: true, message: '请简述合作内容' }],
      },
    }
  },
  computed: {},
  methods: {
    addServiceOrderCooperation() {
      console.log('this.dialogForm', this.dialogForm)
      API.service
        .addServiceOrderCooperation(this.$route.query.id, this.dialogForm)
        .then((res) => {
          console.log('res', res)
          this.handleRes(res)
        })
    },

    handleRes(res) {
      this.$message({
        message: res.message,
        type: 'success',
      })
      this.$router.push('/content/service/cooperation')
    },
    cancelSubmitForm() {
      this.dialogVisible = false
    },
    submitForm() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.addServiceOrderCooperation()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    addCase() {
      this.$emit('addCase')
    },
    resetForm() {
      this.$refs.dialogForm.resetFields()
    },
    autoPrefix(name, key = 'label') {
      // const str = i18nPrefix[key] + name
      // return this.$t(str)
      let str = this[key][name]
      if (key === 'label') {
        str += '：'
      }
      return str
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.dialogCase {
  & /deep/ .el-dialog__header {
    .uni-dialog__header;
  }
  & /deep/ .el-dialog {
    width: 488px;
  }
  & /deep/ .el-dialog__body {
    .uni-dialog__body;
  }
}
</style>
